import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="flex-wrap-mobile">
      <div>
        <Text sx={{
          fontSize: [4, 5, 6],
          fontWeight: `bold`,
          color: `heading`
        }} mdxType="Text">Hi, I’m Edvins 👋</Text>
        <p>{`Welcome to my digital garden 🌱`}</p>
        <p>{`I'm a front-end engineer at `}<a parentName="p" {...{
            "href": "https://secondmind.ai/"
          }}>{`Secondmind`}</a>{`, currently based in Riga, Latvia. I build `}<a href="/projects">{`side projects`}</a>{`, `}<a href="/self-education">{`explore new technologies`}</a>{`, and `}<a href="/blog">{`write about code`}</a>{`. In my free time, I enjoy `}<a href="https://edvins.io/books">{`reading`}</a>{`, running, and spending time with my family.`}</p>
      </div>
      <img src="/profile.jpg" alt="Edvins Antonovs" style={{
        "borderRadius": "50%",
        "width": "200px",
        "height": "200px",
        "marginTop": "20px",
        "marginLeft": "20px",
        "marginBottom": "20px"
      }} />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      